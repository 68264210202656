import React from 'react'
import styled from 'styled-components';
import CardWrapper from './CardWrapper'

const Body = () => {
    return (
        <Container>
            <HeroPortfolio>Our Portfolio</HeroPortfolio>
            <Wrapper>
                <CardWrapper />
            </Wrapper>
        </Container>
    )
}

export default Body

const Container = styled.div`
    margin-top: 70px;
`
const HeroPortfolio = styled.h1`
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 50vh;
    padding: 0 1rem;
    font-size: clamp(1.5rem,6vw, 3rem);
`
const Wrapper = styled.div`
    background-color: #3D6A78;
    height: 100%;
    margin-top: -10px;
    padding: 0rem 1rem;
    
`
