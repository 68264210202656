import React from 'react'
import styled from 'styled-components';
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
//import { Button } from '../Button'

const CardWrapper = () => {
  const data = useStaticQuery(graphql`
    query CardWrapperQuery {
        allMiscImgJson {
          edges {
            node {
              alt
              alts
              alt2
              alt3
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              imgs {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              img2 {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              img3 {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      
    `)

  return (
    <Container>
      <Card data-sal="slide-up" data-sal-easing="ease">
        <ContainerImage
          alt={data.allMiscImgJson.edges[0].node.alt}
          fluid={data.allMiscImgJson.edges[0].node.img.childImageSharp.fluid}
        ></ContainerImage>
        <ContainerHeader>
          <Link to='/'>
            Tripleaim Software Website
            </Link>
        </ContainerHeader>
        <ContainerP>This is a fast, SEO optimized, fully mobile responsive website</ContainerP>
      </Card>
      <Card data-sal="slide-up" data-sal-easing="ease">
        <ContainerImage
          alt={data.allMiscImgJson.edges[0].node.alts}
          fluid={data.allMiscImgJson.edges[0].node.imgs.childImageSharp.fluid}
        ></ContainerImage>
        <ContainerHeader>
          <Link to='https://www.tripleaimsoftware.com/static/1603bf7614ecb21713620c8b61831c44/14b42/portfolio.jpg' target="_blank">
            Treatment Sheet Tracker
            </Link>
        </ContainerHeader>
        <ContainerP>This is a system thats used to log in treatment sheets when brought to the pharmacy. It generates reports on turn around time and staff activities to help in decision making </ContainerP>
        {/* <form name="demo request" method="POST" data-netlify="true" netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="demo request" />
          <FormWrap>
            <label htmlFor="email">
              <input type="email" placeholder="Enter Your Email" id="email" name="email" />
            </label>
            <Button as="button" type="submit" primary="true" css={`width: 100%; height: 50px;`}>Request Demo</Button>
          </FormWrap>
        </form> */}
      </Card>
      <Card data-sal="slide-up" data-sal-easing="ease">
        <ContainerImage
          alt={data.allMiscImgJson.edges[0].node.alt3}
          fluid={data.allMiscImgJson.edges[0].node.img3.childImageSharp.fluid}
        ></ContainerImage>
        <ContainerHeader>
          <Link to='https://www.tripleaimsoftware.com/static/2dd9d0f80512b36899a3af4983778c1f/ee604/ipharmaheal.png' target="_blank">
            Online Pharmacy Platform
            </Link>
        </ContainerHeader>
        <ContainerP>This is a web-based online pharmacy platform that enables ecommerce and billing</ContainerP>
      </Card>
      <Card data-sal="slide-up" data-sal-easing="ease">
        <ContainerImage
          alt={data.allMiscImgJson.edges[0].node.alt2}
          fluid={data.allMiscImgJson.edges[0].node.img2.childImageSharp.fluid}
        ></ContainerImage>
        <ContainerHeader>
          <Link to='https://www.tripleaimsoftware.com/static/8b42f274d81f1abd8182fbc29cac2b5e/ee604/dashboard.png' target="_blank">
            Sales Dashboard
            </Link>
        </ContainerHeader>
        <ContainerP>This is a real time interactive dashboard showing various insights of a company. Data has been altered for confidentiality</ContainerP>
      </Card>
    </Container>
  )
}

export default CardWrapper

const Container = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`
const Card = styled.div`
    background: #fff;
    max-width: 400px;
    margin-top: 4rem;
    border-radius: 5%;
    margin-bottom: 4rem;
    height: 100%;

    :hover {
      transform: scale(1.05)
  }
`
const ContainerImage = styled(Img)`
    width: 100%;
    height: 50%;
    margin-top: -10px;
`
const ContainerHeader = styled.h3`
    text-align: center;
    font-size: clamp(1.2rem,3vw, 1.5rem);
    margin-bottom: 1rem;
`
const ContainerP = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    margin-bottom: 1rem;
`
// const FormWrap = styled.div`
//     display: flex;
//     flex-direction: column;
//     padding: 1rem 2rem;


//  input {
//      padding: 1rem 1,5rem;
//      outline: none;
//      width: 100%;
//      height: 50px;
//      border-radius: 20px;
//      border: 2px solid #71C4DE;
//      margin-bottom: 1rem;
//      text-align: center;
//  }
// `
