import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Body from '../components/portfolio/Body'


const Portfolio = () => (
    <Layout>
        <SEO title="Portfolio - health information system kenya portfolio"
            description="Health information systems we have developed to improve the healthcare system - patient experience, quality of care, healthcare cost"
            keywords="kenya web design, web design in kenya, treatment sheet tracker, online pharmacy platform, sales dashboard, software company in kenya, data visualization in Kenya, data analytics Kenya, front end developer in Nairobi Kenya, back end developer in Nairobi Kenya"
            siteUrl="https://www.tripleaimsoftware.com/portfolio/">
        </SEO>
        <Body />
    </Layout>
)
export default Portfolio
